export abstract class FirebaseService {
  protected normalizeSnapshotToArray<T extends any>(
    snapshot: Record<string, T>
  ) {
    return Object.keys(snapshot).map(key =>
      this.normalizeObjectLikeSnapshot(snapshot[key], key)
    )
  }

  protected normalizeObjectLikeSnapshot<T extends Record<string, any>>(
    snapshot: T,
    identity: string
  ) {
    const obj = snapshot as any
    obj.id = identity
    obj.theKey = identity
    return obj
  }

  protected deleteIdentity<T extends { id?: string; theKey?: string }>(
    object: T
  ): Omit<T, "id" | "theKey"> {
    const { id, theKey, ...rest } = object
    return rest
  }
}

export type Snapshot<T> = Record<string, T>

export type Maybe<T> = T | null

export type Denormalized<T extends Record<string, any>> = Omit<T, "id">
