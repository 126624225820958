import { db } from "@/firebase"

import type { User, UserRole } from "@/types/user"

import {
  Denormalized,
  Maybe,
  Snapshot,
  FirebaseService
} from "./firebase.service"

export default class UserService extends FirebaseService {
  getUsersByClientID(clientID: string) {
    return this._getClientUsersRef(clientID)
      .once("value")
      .then(snapshot => {
        const value: Maybe<Snapshot<Denormalized<User>>> = snapshot.val()
        return value ? this.normalizeSnapshotToArray(value) : []
      })
  }

  getUsersByRole(role: UserRole): Promise<User[]> {
    return db
      .ref(`org/1/users`)
      .orderByChild("role")
      .equalTo(role)
      .once("value")
      .then(snapshot => {
        const value: Maybe<Snapshot<Denormalized<User>>> = snapshot.val()
        return value ? this.normalizeSnapshotToArray(value) : []
      })
  }

  subscribeToClientUsers(clientID: string, subscriber: (users: User[]) => any) {
    const ref = this._getClientUsersRef(clientID)

    const decorator = snapshot => {
      const value: Maybe<Snapshot<Denormalized<User>>> = snapshot.val()
      subscriber(value ? this.normalizeSnapshotToArray(value) : [])
    }

    ref.on("value", decorator)

    return () => {
      ref.off("value", decorator)
    }
  }

  private _getClientUsersRef(clientID: string) {
    return db
      .auxiliary(clientID)
      .ref(`org/1/users`)
      .orderByChild("clientID")
      .equalTo(clientID)
  }
}
